.App {
  background-color: black;
  color:rgb(165, 164, 164);
  height: 100vh;
  overflow: auto; /* fills screen */
  text-align: center;
}

.main {
  margin: auto;
  max-width: 1100px;
}

.map-container {
  height: 96vh;
}

.legend {
  z-index: 1;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 28px 10px;
  border-radius: 4px;
  background-color: rgba(252, 252, 252, 0.94);
  /* background-color: rgba(35, 55, 75, 0.8); */
  color: #353535;
}

.legend-container {
  font-family: monospace;
  padding: 6px 12px;
  text-align: left;
}

.legend-container span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}